import React from 'react'
import Layout from 'layouts/en'
import Template from 'templates/faq'

export default () => {
  return (
    <Layout p={5}>
      <Template lang="de" list={list} />
    </Layout>
  )
}

const list = (
  <section className="template">
    <h1 className="headline">FAQ</h1>
    <article className="faq">
      <div className="question">
        <p className="text bold">Was ist Stairlin?</p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Stairlin ist ein Technologieunternehmen mit Hauptsitz in Lausanne und
          Niederlassungen in Zürich. Gegenwärtig sind wir darum bemüht, den
          ersten komplett digitalisierten Marktplatz für Dienstleistungen in der
          Schweiz und in Europa aufzubauen.
          <br />
          Unser Unternehmen ist darauf ausgerichtet, clevere Lösungen für jene
          unnötigen Alltagssorgen zu finden, die uns Tag für Tag fordern und
          belasten: haben wir der Versicherung die Rechnung zukommen lassen?
          Haben wir zurückgerufen, um den Termin abzusagen? Wann werde ich
          erfahren, ob die Buchung bestätigt wurde? Unser Ziel ist es, sie von
          diesen unzähligen Kleinigkeiten, die ihnen wertvolle Zeit rauben, zu
          befreien und ihnen mehr Raum für die Dinge zu schaffen, die wirklich
          zählen: für ihre Freunde, Familie und Kunden.
        </p>
      </div>
    </article>
    <article className="faq">
      <div className="question">
        <p className="text bold">Wobei ist Stairlin behilflich?</p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Die Suche, das Buchen und die Bezahlung von Dienstleistungen ist
          meistens noch sehr aufwendig in der Schweiz und in Europa. Bei
          kleineren Serviceanbietern werden Buchung, Bezahlung und
          Kundenmanagement oftmals noch manuell durchgeführt. Alles, was die
          Buchungen betrifft, wird ebenfalls hauptsächlich persönlich erledigt
          (Rechnungsversand oder -verfolgung, Zahlungsüberprüfung usw.).
          <br />
          Deshalb möchten wir einen Service-Marktplatz aufbauen, auf dem jeder
          Anbieter sein Business ganz einfach starten oder verwalten kann und
          jeder Kunde genau das, was er benötigt, in weniger als 15 Sekunden
          finden, buchen und bezahlen kann.
        </p>
      </div>
    </article>
    <article className="faq">
      <div className="question">
        <p className="text bold">Wie kann ich anfangen Stairlin zu benutzen?</p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Sie müssen sich nur auf unserer Website registrieren und ein
          Firmenprofil erstellen. Nach wenigen Minuten können sie bereits Ihre
          Dienste rund um die Uhr ihren Kunden online anbieten (über Google,
          Facebook, Instagram, ihre eigene Website usw.).
        </p>
      </div>
    </article>
    <article className="faq">
      <div className="question">
        <p className="text bold">Ist Stairlin kompatibel mit T590?</p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Jawohl! Rechnungen werden automatisch bei Stairlin erstellt und sind
          völlig mit T590 kompatibel.
        </p>
      </div>
    </article>
    <article className="faq">
      <div className="question">
        <p className="text bold">
          Wie kann ich mein Business bei Stairlin anmelden?
        </p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Dazu müssen sie nur Registrieren auf unserer Website auswählen. Danach
          können sie in weniger als zwei Minuten ihr Business starten. Sie
          müssen bloss einige grundlegende Informationen zu ihrem Unternehmen
          angeben; den Bereich, in dem das Unternehmen tätig ist, eine kurze
          Unternehmensbeschreibung sowie ein paar weitere Einzelheiten.
        </p>
      </div>
    </article>
    <article className="faq">
      <div className="question">
        <p className="text bold">
          Wie kann ich mein Business auf Stairlin einrichten, damit Kunden das
          Angebot buchen können?
        </p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Für die Öffnungszeiten: klicken sie auf das Stairlin Logo, wählen sie
          Business-Einstellungen, Kalender und schliesslich Verfügbarkeit.
          <br />
          Um ihr Angebot einzustellen: klicken sie auf das Stairlin Logo, wählen
          sie Business-Einstellungen.
        </p>
      </div>
    </article>
    <article className="faq">
      <div className="question">
        <p className="text bold">
          Ist für Stairlin eine Installation oder ein Download erforderlich?
        </p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Damit Stairlin funktioniert, sind keinerlei Installationen notwendig.
          Sie benötigen nur einen Webbrowser auf einem Smartphone, Tablet oder
          Computer und eine Internetverbindung.
          <br />
          Unabhängig davon werden wir in Zukunft eine App bereitstellen, um die
          Anwendung für Nutzer von Smartphones und Tablets zu verbessern. Dabei
          wird es sich aber um eine optionale App handeln.
        </p>
      </div>
    </article>
    <article className="faq">
      <div className="question">
        <p className="text bold">Wo können Kunden meine Angebote buchen?</p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Mit Stairlin können Kunden ihre Angebote von überall dort, wo sie es
          wünschen, buchen. Sie kennen ihre Kunden am besten: treten sie mit
          ihnen überwiegend über Facebook in Kontakt? Auf Instagram? Über ihre
          Website? Über Google? Sie müssen nur ihre Stairlin Business-Seite auf
          diesen Plattformen verlinken und ihre Kunden werden ihr Angebot direkt
          buchen können.
        </p>
      </div>
    </article>
    <article className="faq">
      <div className="question">
        <p className="text bold">
          Ich musste nie ein Passwort eingeben. Ist das normal?
        </p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Ja! Da Passwörter schwierig zu merken sind, tendiert man dazu immer
          das Gleiche zu verwenden. Datenübertragungen finden täglich statt und
          bereits eine einzelne Übertragung stellt eine mögliche Gefahr dar,
          dass Ihr Passwort in falsche Hände gelangt. Aus diesem Grund haben wir
          uns entschieden die Authentifizierung auf eine andere Weise
          durchzuführen.
          <br />
          Es gibt drei verschiedene Formen der Benutzer-Authentifizierung. Die
          erste ist Wissen (etwas was Sie wissen), wie z.B. Passwörter, PIN,
          etc. Die zweite ist Besitz (etwas was Sie haben), wie z.B.
          Telefonnummer, emails, key fobs, etc. Die letzte ist Anhaftung (etwas
          was Sie sind), wie z.B. Fingerabdruck, Gesichtserkennung, etc.
          <br />
          Unser System nutzt zur Authentifizierung in einem ersten Schritt den
          Besitz. Jedes Mal, wenn Sie sich mit einem unbekannten Gerät auf Ihr
          Konto einloggen wollen, wird ein 6-stelliger Code per SMS an Ihre
          Telefonnummer geschickt. So kann sich nur der Besitzer der
          Telefonnummer auf Ihr Konto Zugang verschaffen.
          <br />
          Wir planen in naher Zukunft die Mehr-Faktor-Authentifizierung zu
          ermöglichen und möchten Ihnen damit nochmals unterstreichen, wie
          wichtig uns Ihre Sicherheit ist.
        </p>
      </div>
    </article>
    <article className="faq">
      <div className="question">
        <p className="text bold">
          Ist die Kommunikation und die Datensicherung sicher?
        </p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Die komplette Kommunikation zwischen Ihrem Gerät und Stairlin findet
          in einem abgesicherten und verschlüsselten Weg statt. Aktuell nutzen
          wir das TLS 1.2 mit einem RSA 2048 bits Schlüssel. Zudem kommunizieren
          alle unsere Server über ein virtuelles Netzwerk, welches von anderen
          Servern isoliert ist. Schliesslich werden alle unsere Daten
          verschlüsselt in einem Datencenter abgespeichert, welches PCI DSS
          konform, nach ISO 27001 zertifiziert und nach strikten
          Datenschutzbestimmungen geführt ist.
        </p>
      </div>
    </article>
    <article className="faq">
      <div className="question">
        <p className="text bold">Wie kann ich meinen Account löschen?</p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Falls ihnen ein Business gehört, gehen Sie einfach auf ihren
          Business-Account und klicken auf Business-Einstellungen. Unter der
          Überschrift “Account” befindet sich dort ein Link mit dem Titel
          “Account löschen”.
          <br />
          Sobald ihr Business-Account gelöscht wurde, können sie unter den
          Nutzereinstellungen ihren privaten Account löschen.
        </p>
      </div>
    </article>
  </section>
);
